import React from 'react';
import Icon from './icon';

function GoogleIcon(props) {
  return (
    <Icon
      name="google"
      size={30}
      viewBox="0 0 30 30"
      {...props}
      renderComponent={() => (
        <g data-name="google">
          <path
            d="M23.64 15.2a11 11 0 0 0-.16-1.84H15v3.48h4.84a4.15 4.15 0 0 1-1.79 2.72v2.26H21a8.76 8.76 0 0 0 2.68-6.61z"
            fill="#4285f4"
          />
          <path
            d="M15 24a8.62 8.62 0 0 0 6-2.18l-2.91-2.26a5.43 5.43 0 0 1-3.05.86 5.37 5.37 0 0 1-5-3.71H7V19a9 9 0 0 0 8 5z"
            fill="#34a853"
          />
          <path
            d="M10 16.71a5.36 5.36 0 0 1 0-3.42V11H7a9 9 0 0 0 0 8z"
            fill="#fbbc05"
          />
          <path
            d="M15 9.58a4.83 4.83 0 0 1 3.44 1.35L21 8.35A8.61 8.61 0 0 0 15 6a9 9 0 0 0-8 5l3 2.33a5.37 5.37 0 0 1 5-3.75z"
            fill="#ea4335"
          />
        </g>
      )}
    />
  );
}

export default GoogleIcon;
