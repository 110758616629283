import { Connector } from 'Webapp/utils/connector';

import { setAuthFormLoading } from 'Webapp/shared/app/redux/actions/auth-actions.js';

const selectors = {};
const actions = { setAuthFormLoading };

export type ConnectAuthFormProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const connectAuthentication: Connector = {
  selectors,
  actions,
};

export default connectAuthentication;
