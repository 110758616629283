import Config from 'Config';

import { noOpFn } from 'Utils/no-op-fn';
import getWindow from 'Utils/get-window';

/**
 * Creates a script element and injects into the <head> of the document
 * @param {String} url - source of script
 * @param {Function} reject - Optional function to be called if the script fails to load
 */
function createScriptElement(url, resolve = noOpFn, reject = noOpFn) {
  const script = getWindow().document.createElement('script');
  script.async = true;
  script.src = url;
  script.onerror = reject;
  script.onload = resolve;
  getWindow().document.head.appendChild(script);
}

const APPLE_SDK_EVENTS = {
  SIGNIN_SUCCESS: 'AppleIDSignInOnSuccess',
  SIGNIN_FAILURE: 'AppleIDSignInOnFailure',
};

/**
 * Loads the Apple SDK
 * @returns Promise - resolves if/when the SDK is loaded.
 */
function loadAppleSDK(redirectURI) {
  const initializeAppleSDK = () => {
    getWindow().AppleID.auth.init({
      clientId: Config.APPLE_SDK_CLIENT_ID,
      scope: Config.APPLE_SDK_SCOPE,
      redirectURI,
      state: Config.APPLE_SDK_OAUTH_STATE,
      usePopup: true,
    });
  };
  if (getWindow().AppleID && getWindow().AppleID.auth) {
    initializeAppleSDK();
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    createScriptElement(
      Config.APPLE_SDK_URL,
      () => {
        initializeAppleSDK();
        resolve();
      },
      reject,
    );
  });
}

export { loadAppleSDK, APPLE_SDK_EVENTS };

export default {
  /**
   * Loads the Facebook SDK
   * @returns Promise - resolves if/when the SDK is loaded.
   */
  loadFacebookSDK() {
    if (getWindow().FB && getWindow().FB.getAuthResponse) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      createScriptElement(Config.FACEBOOK_SDK_URL, noOpFn, reject);
      getWindow().fbAsyncInit = function () {
        getWindow().FB.init({
          appId: Config.FACEBOOK_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: Config.FACEBOOK_SDK_VERSION,
        });
        resolve();
      };
    });
  },

  getFacebookAuthStatus(callback) {
    getWindow().FB.getLoginStatus(callback, true);
  },

  loginWithFacebook(onAuth, isSignup = false) {
    if (isSignup) {
      const options = {
        scope: 'email',
      };
      getWindow().FB.login(onAuth, options);
    } else {
      getWindow().FB.login(onAuth);
    }
  },
  /**
   * Points to Auth App's server endpoint for handling Twitter SSO
   */
  loginWithTwitter() {
    getWindow().location = '/auth/twitter';
  },
};
