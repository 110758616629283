import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SURFACE_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { FONT_WEIGHTS } from 'Style/typography';
import { BREAKPOINTS } from 'Style/breakpoints';

const StyledAuthModule = styled.div({
  borderRadius: '5px',
  padding: `${SPACING.BASE4X} ${SPACING.BASE6X} ${SPACING.XLARGE} ${SPACING.BASE6X}`,
  ...BREAKPOINTS.phone({ width: '100%', height: '100%', borderRadius: 0 }),
  backgroundColor: SURFACE_COLORS.primary,
  textAlign: 'center',

  a: {
    fontWeight: FONT_WEIGHTS.SEMIBOLD,
  },

  form: {
    marginBottom: SPACING.BASE6X,
  },
});

const AuthModule = ({ className, children }) => (
  <StyledAuthModule className={className}>{children}</StyledAuthModule>
);

AuthModule.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AuthModule.defaultProps = {
  className: '',
};

export default AuthModule;
