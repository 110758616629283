import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
// Utils
import getWindow from 'Utils/get-window';
import logger from 'Utils/logger';
import { GA } from 'Utils/analytics';
import Config from 'Config';
import {
  loadAppleSDK,
  APPLE_SDK_EVENTS,
} from 'Webapp/shared/utils/third-party-auth-service';
import { COLORS } from 'Style/colors';

// Components
import Button, { StyleModifiers } from 'Webapp/shared/app/components/button';
import AppleIcon from 'ComponentLibrary/icons/apple';

import { buttonActiveStateShadow, disabledState } from 'Style/definitions';

const AppleLoginButton = styled.div({
  position: 'absolute',
  opacity: '0',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  width: '100%',
  height: '100%',
});

const AppleLogginWrapper = styled.div({
  position: 'relative',
  cursor: 'pointer',
  overflow: 'hidden',

  '&:hover, &:focus': {
    '.button--with-badge--social': {
      boxShadow: '0 0 1px rgba(0, 0, 0, 0.54)',
      border: `1px solid ${COLORS.themeReverse}`,
      span: {
        color: 'rgba(0, 0, 0, 0.64)',
      },
    },
  },
  '&:active': {
    '.button--with-badge--social': {
      borderColor: COLORS.themeReverse,
      ...buttonActiveStateShadow,
    },
  },
  '&:disabled': {
    '.button--with-badge--social': {
      ...disabledState,
    },
  },
});
class AppleLogin extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    this.handleAuthSuccess = this.handleAuthSuccess.bind(this);
    this.handleAuthError = this.handleAuthError.bind(this);
    this.handleClickFakeButton = this.handleClickFakeButton.bind(this);
  }

  componentDidMount() {
    loadAppleSDK(Config.APPLE_SDK_REDIRECT_URI).then(() => {
      this.setState({ isLoading: false });
    });

    getWindow().document.addEventListener(
      APPLE_SDK_EVENTS.SIGNIN_SUCCESS,
      this.handleAuthSuccess,
    );

    getWindow().document.addEventListener(
      APPLE_SDK_EVENTS.SIGNIN_FAILURE,
      this.handleAuthError,
    );
  }

  componentWillUnmount() {
    getWindow().document.removeEventListener(
      APPLE_SDK_EVENTS.SIGNIN_SUCCESS,
      this.handleAuthSuccess,
    );
    getWindow().document.removeEventListener(
      APPLE_SDK_EVENTS.SIGNIN_FAILURE,
      this.handleAuthError,
    );
  }

  handleAuthSuccess(e) {
    const { detail } = e;
    const idToken =
      (detail && detail.authorization && detail.authorization.id_token) || null;
    if (!idToken) {
      this.setState({ isLoading: false });
      return this.props.onError('apple');
    }

    this.props.onAuth('apple', idToken);
  }

  handleAuthError(e) {
    logger.error(e.detail && e.detail.error);
    this.setState({ isLoading: false }, () => this.props.onError('apple'));
  }

  // As we have a transparent Button injected by the Apple SDK displayed above our own
  // Button component, manually fire events for this button.
  handleClickFakeButton() {
    GA.trackClickButton('apple-login');
  }

  render() {
    return (
      <AppleLogginWrapper onClick={this.handleClickFakeButton}>
        <Button
          name="apple-login"
          className="button--with-badge--social button--with-badge--auth button--with-badge--apple--auth"
          styleModifier={[StyleModifiers.WITH_BADGE, StyleModifiers.LITTLE]}
          disabled={this.state.isLoading}
        >
          <AppleIcon color="#000" size={18} />
        </Button>
        <AppleLoginButton
          id="appleid-signin"
          className="apple-login-button"
          data-color="black"
          data-border="true"
          data-type="sign in"
        />
      </AppleLogginWrapper>
    );
  }
}

AppleLogin.propTypes = {
  onAuth: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AppleLogin;
