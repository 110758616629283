import React from 'react';
import PropTypes from 'prop-types';
import withT from 'ComponentLibrary/hocs/withT';

import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';

const StyledTermsOfUseAndPrivacyPolicy = styled.div({
  paddingTop: SPACING.XLARGE,
});
const PolicyText = styled.p(UI_TEXT_TYPES.SUPPORTING);

const TermsOfUseAndPrivacyPolicy = ({ t }) => (
  <StyledTermsOfUseAndPrivacyPolicy>
    <PolicyText>
      {t('by_continuing_you_accept')}&nbsp;
      <a href="https://about.flipboard.com/terms">{t('terms_of_use')}</a>{' '}
      {t('and')}&nbsp;
      <a href="https://about.flipboard.com/privacy">{t('privacy_policy')}</a>.
    </PolicyText>
  </StyledTermsOfUseAndPrivacyPolicy>
);

TermsOfUseAndPrivacyPolicy.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withT(TermsOfUseAndPrivacyPolicy);
