import React from 'react';
import PropTypes from 'prop-types';

import getWindow from 'Utils/get-window';

// Components
import GoogleIcon from 'ComponentLibrary/icons/google';
import Button, { StyleModifiers } from 'Webapp/shared/app/components/button';
import { useGoogleLogin } from '@react-oauth/google';
import connector from 'Utils/connector';
import connectAuthForm from 'Webapp/shared/app/connectors/connectAuthForm';

const GoogleLogin = ({ onAuth, onError, setAuthFormLoading }) => {
  const login = useGoogleLogin({
    onSuccess: (response) => {
      onAuth('googleplus', response.access_token);
    },
    onError: () => {
      setAuthFormLoading(false);
      onError('google');
    },
    onNonOAuthError: () => {
      setAuthFormLoading(false);
    },
  });

  return (
    <Button
      name="google-login"
      className="button--with-badge--social button--with-badge--auth button--with-badge--google--auth"
      styleModifier={[
        StyleModifiers.WITH_BADGE,
        StyleModifiers.INLINE,
        StyleModifiers.LITTLE,
      ]}
      disabled={!getWindow().google}
      onClick={() => {
        setAuthFormLoading(true);
        login();
      }}
    >
      <GoogleIcon />
    </Button>
  );
};
GoogleLogin.propTypes = {
  onAuth: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  setAuthFormLoading: PropTypes.func.isRequired,
};

export default connector(connectAuthForm)(GoogleLogin);
